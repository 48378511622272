.custom-dropdown .ant-select-item {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px !important;
  // border: 1px solid black !important;
}

.custom-dropdown .ant-select-item-option-active {
  background-color: #f0f0f0;
}

.custom-dropdown .ant-select-item-option-selected {
  background-color: #f0f0f0;
  // border: 1px solid black !important;
}

.custom-dropdown {
  .ant-select-item {
    display: flex;
    justify-content: space-between;
  }
}

.font20Bold {
  font-size: 20px;
  font-weight: bold;
}

.marginBottom10 {
  margin-bottom: 10px;
}
body .ant-modal-root .bnpl-installment-table .ant-table-cell {
  padding: 5px;
}
body .BAFL-card-modal {
  h2 {
    font-family: "ZemestroStd-Bold";
  }

  .ant-table-thead {
    .ant-table-cell.table-header {
      background-color: #f0f0f0;
      border-start-end-radius: 0px !important;
      border-start-start-radius: 0px !important;
      font-family: "ZemestroStd-Medium";
    }
  }
}
.bnpl-component-container {
  width: 94%;
  margin: auto;
  padding-bottom: 10px;
}
body .bnpl-helper-text-container {
  display: flex;
}
body .bnpl-helper-text {
  margin: 0;
  justify-content: start;
  text-align: left;
  font-size: 12px;
}
.bnpl-helper-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.all-banks-credicts-heading {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  img {
    max-height: 60px;
    object-fit: contain;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-3px);
    }
  }

  h2 {
    position: relative;
    margin: 0;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;

    // Vertical line separator
    &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: 3px;
      background: #000;
      border-radius: 4px;
    }
  }
}

.creditCards {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  img {
    max-height: 60px;
    object-fit: contain;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-3px);
    }
  }

  h2 {
    position: relative;
    margin: 0;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;

    // Vertical line separator
    &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: 3px;
      background: #000;
      border-radius: 4px;
    }
  }
}

@media (max-width: 767px) {
  .all-banks-credicts-heading h2 {
    padding-left: 15px;
    font-size: 1rem !important;
  }
}
